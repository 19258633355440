import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../firebase";
import {
  FaUserAlt,
  FaExternalLinkAlt,
  FaEdit,
  FaShoppingCart,
  FaSignOutAlt,
  FaUserCog,
} from "react-icons/fa";
import { Navbar, Nav, NavDropdown } from "react-bootstrap"; // Import React Bootstrap components

import logo from "../../../assets/images/logo.png";

const AdminNav = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/mediadmin/auth/login");
    } catch (error) {
      console.log("Error occurred during logout:", error);
    }
  };

  return (
    <Navbar
      expand='lg'
      variant='dark'
      className='border-bottom border-bottom-dark position-sticky top-0'
      style={{ zIndex: "1000" }}
    >
      <div className='container'>
        <Navbar.Brand as={Link} to='/mediadmin/dashboard'>
          <img
            src={logo}
            alt='Mediformers Dashboard'
            style={{ width: "150px", height: "auto" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='adminNavbar' />
        <Navbar.Collapse id='adminNavbar'>
          <Nav className='ms-auto'>
            <Nav.Link as={Link} to='/' target='_blank'>
              <FaExternalLinkAlt /> Visit Website
            </Nav.Link>
            <Nav.Link as={Link} to='/mediadmin/dashboard/add-products'>
              <FaShoppingCart /> Add Products
            </Nav.Link>
            <Nav.Link as={Link} to='/mediadmin/dashboard/manage-products'>
              <FaEdit /> Manage Products
            </Nav.Link>
            <NavDropdown
              title={<FaUserAlt />}
              id='adminDropdown'
              menuAlign='right'
            >
              <NavDropdown.Item as={Link} to='/mediadmin/dashboard/profile'>
                <FaUserCog /> Profile Settings
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout}>
                <FaSignOutAlt /> Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default AdminNav;
