import React, { useEffect } from "react";
import ReactGA from "react-ga";
import notFoundImage from "../assets/images/404.svg";

function ErrorPage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div
      className='d-flex flex-column align-items-center justify-content-center p-5 text-white position-relative'
      style={{ height: "82vh" }}
    >
      <div className='bg-primary-color p-5 rounded-3'>
        <h1 className='fw-bold text-center text-shadow'>404 - Page Not Found</h1>
        <p className='text-center'>
          The page you are looking for does not exist.
        </p>
      </div>
      <img
        src={notFoundImage}
        className='position-absolute top-0 star-0 m-5'
        alt=""
        style={{ zIndex: -1 }}
      />
    </div>
  );
}

export default ErrorPage;
