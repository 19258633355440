import React from "react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';

import AdminNav from "../components/Admin/Dashboard/AdminNav";
import AddProduct from "../components/Admin/Dashboard/AddProduct";
import ManageProducts from "../components/Admin/Dashboard/ManageProducts";
import Profile from "../components/Admin/Dashboard/Profile";

const AdminPanel = () => {
  document.title = "Mediformers | Admin Panel";

  return (
    <div>
       {/* Add the React Helmet component */}
       <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      
      <AdminNav />
      <Routes>
        <Route path='dashboard/add-products' element={<AddProduct />} />
        <Route path='dashboard/manage-products' element={<ManageProducts />} />
        <Route path='dashboard/profile' element={<Profile />} />
      </Routes>
    </div>
  );
};

export default AdminPanel;
