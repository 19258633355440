const types = [
  { id: 1, name: "Capsule", selected: false },
  { id: 2, name: "Drops", selected: false },
  { id: 3, name: "Food Supplement", selected: false },
  { id: 4, name: "Sachet", selected: false },
  { id: 5, name: "Syrup", selected: false },
  { id: 6, name: "Tablet", selected: false },
];

const categories = [
  { id: 1, name: "Cardiology", selected: false },
  { id: 2, name: "Neurology", selected: false },
  { id: 3, name: "Ophthalmology", selected: false },
  { id: 4, name: "Urology", selected: false },
  { id: 5, name: "Orthopedics", selected: false },
  { id: 6, name: "Pediatrics", selected: false },
  { id: 7, name: "Obstetrics & Gynecology", selected: false },
  { id: 8, name: "Dermatology", selected: false },
  { id: 9, name: "Gastroenterology", selected: false },
  { id: 10, name: "Psychiatry", selected: false },
  { id: 11, name: "Genrel Health", selected: false },
  { id: 12, name: "Hepatology", selected: false },
];

 // Sorting Categories
 const sortedCategories = categories
 .slice()
 .sort((a, b) => a.name.localeCompare(b.name));

// Sorting Types
const sortedTypes = types
 .slice()
 .sort((a, b) => a.name.localeCompare(b.name));

export { sortedTypes, sortedCategories };
