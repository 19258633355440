import React from "react";
import ProductForm from "./ProductForm";
import { Container } from "react-bootstrap";
import { FaCartPlus } from "react-icons/fa";

const AddProduct = () => {
  return (
    <Container>
      <h1 className='text-center mt-5 text-primary-color'>
        {" "}
        <FaCartPlus /> Add Product
      </h1>
      <hr />
      <ProductForm isEditing={false} />
    </Container>
  );
};

export default AddProduct;
