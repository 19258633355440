import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { sortedTypes, sortedCategories } from "../components/data";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import ProductCard from "../components/Products/ProductCard";
import ProductDetailsModal from "../components/Products/ProductDetailsModal";
import ProductFilter from "../components/Products/ProductFilter";
import ProductHeader from "../components/Products/ProductHeader";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";

const ProductsPage = () => {
	// State for type filter and category filter
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedType, setSelectedType] = useState("");
	const [selectedCategory, setSelectedCategory] = useState("");
	const [products, setProducts] = useState([]);

	const navigate = useNavigate();
	const location = useLocation();
	const [productUrl, setProductUrl] = useState("");
	const [showLiteratureModal, setShowLiteratureModal] = useState(false);

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const querySnapshot = await getDocs(collection(db, "Products"));
				const fetchedProducts = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setProducts(fetchedProducts);
			} catch (error) {
				console.error("Error fetching products:", error);
			}
		};

		fetchProducts();
	}, []);

	// Filtered products based on search and filter criteria
	const filteredProducts = products.filter((product) => {
		return (
			product.productName.toLowerCase().includes(searchQuery.toLowerCase()) &&
			(selectedType === "" || product.selectedTypes[0].name === selectedType) &&
			(selectedCategory.length === 0 ||
				product.selectedCategories.some((category) =>
					selectedCategory.includes(category.name)
				))
		);
	});

	// State to track the selected product and modal visibility
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showFilterModal, setShowFilterModal] = useState(false);

	// Effect to show the modal based on the 'section' query parameter
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const modalValue = searchParams.get("modal");

		if (modalValue) {
			// Find the product with the matching modalValue
			const product = products.find((product) => product.id === modalValue);
			if (product) {
				setShowModal(true);
				setSelectedProduct(product);
			}

			// Open the LiteratureModal if 'literature' parameter is present in the location.search
			if (location.search.includes("literature")) {
				setShowLiteratureModal(true);
			}
		}
	}, [location, products]);

	// Effect to close the modal if the modal is opened when the user clicks the back button
	useEffect(() => {
		const handlePopstate = (event) => {
			if (!event.state.modal) {
				setShowModal(false);
			}
		};

		window.addEventListener("popstate", handlePopstate);

		return () => {
			window.removeEventListener("popstate", handlePopstate);
		};
	}, []);

	// Function to handle click on a card and show the modal
	const handleCardClick = (product) => {
		setSelectedProduct(product);
		setShowModal(true);
		const productAddress = `/products/?modal=${product.id}`;
		setProductUrl(productAddress);

		navigate(productAddress, { state: { modal: true } });
	};

	// Check if any filters are applied
	const anyFiltersApplied = selectedType !== "" || selectedCategory.length > 0;

	// Sort the products by name
	const sortedProducts = filteredProducts
		.slice()
		.sort((a, b) => a.productName.localeCompare(b.productName));

	return (
		<>
			<ProductHeader />
			<Container className='my-5'>
				<Row>
					<Col md={4} lg={3}>
						{/* Add the filter icon */}
						<Button
							variant='primary'
							className='d-block d-md-none fixed-bottom w-50 mx-auto mb-3 border-0 '
							onClick={() => setShowFilterModal(true)}>
							{anyFiltersApplied ? <MdFilterAltOff /> : <MdFilterAlt />} Filter
						</Button>
						<div className='d-none d-md-block'>
							<ProductFilter
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
								selectedType={selectedType}
								setSelectedType={setSelectedType}
								selectedCategory={selectedCategory}
								setSelectedCategory={setSelectedCategory}
								types={sortedTypes}
								categories={sortedCategories}
							/>
						</div>
					</Col>

					<Col md={8} lg={9}>
						<div className='row'>
							{sortedProducts.map((product) => (
								<div key={product.id} className='col-md-4 mb-4'>
									<ProductCard
										product={product}
										onClick={() => handleCardClick(product)}
									/>
								</div>
							))}
						</div>
					</Col>
				</Row>

				{/* Show the product details modal */}
				{selectedProduct && (
					<ProductDetailsModal
						product={selectedProduct}
						show={showModal}
						onHide={() => {
							setShowModal(false);
							navigate("/products");
						}}
						productUrl={productUrl}
						showLiteratureModal={showLiteratureModal}
					/>
				)}
			</Container>

			{/* Show the filter modal */}
			<Modal
				show={showFilterModal}
				onHide={() => setShowFilterModal(false)}
				size='sm'>
				<Modal.Body className='p-0'>
					<ProductFilter
						searchQuery={searchQuery}
						setSearchQuery={setSearchQuery}
						selectedType={selectedType}
						setSelectedType={setSelectedType}
						selectedCategory={selectedCategory}
						setSelectedCategory={setSelectedCategory}
						types={sortedTypes}
						categories={sortedCategories}
						mobile={true}
						onHide={() => setShowFilterModal(false)}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ProductsPage;
