import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { BrowserView, TabletView } from "react-device-detect";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

import Home from "./pages/Home";
import Products from "./pages/Products";
import AdminPanel from "./pages/AdminPanel";
import ErrorPage from "./pages/ErrorPage";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./components/Admin/Auth/Login";
import ForgotPassword from "./components/Admin/Auth/ForgotPassword";
import PrivateRoute from "./components/PrivateRoute";

import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthenticated(!!user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route
            path='/*'
            element={<LayoutWrapper authenticated={authenticated} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

function LayoutWrapper({ authenticated }) {
  const location = useLocation();
  const isAdminPanel = location.pathname.startsWith("/mediadmin");

  return (
    <>
      {!isAdminPanel && <Navbar />}
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/products' element={<Products />} />
        <Route exact path='/mediadmin/auth/login' element={<Login />} />
        <Route
          exact
          path='/mediadmin/auth/forgot-password'
          element={<ForgotPassword />}
        />
        <Route
          path='/mediadmin/*'
          element={
            <PrivateRoute authenticated={authenticated}>
              <AdminWrapper />
            </PrivateRoute>
          }
        />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
      {!isAdminPanel && <Footer />}
    </>
  );
}

function AdminWrapper() {
  const [user, setUser] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate("/mediadmin/auth/login");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <>
      {user ? (
        <BrowserView>
          <AdminPanel />
        </BrowserView>
      ) : (
        <Login />
      )}
      <TabletView>
        <p>Admin panel is not available on mobile devices.</p>
      </TabletView>
    </>
  );
}

export default App;
