import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";

import { FaHome, FaInfoCircle, FaEnvelope, FaCapsules } from "react-icons/fa";

import logo from "../assets/images/logo.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname, location.search);
    document.title = pageTitle ? `MediFormers | ${pageTitle}` : "MediFormers";
  }, [location]);

  const handleRedirect = (sectionId) => {
    navigate(`/?section=${sectionId}`);
  };

  const getPageTitle = (pathname, search) => {
    if (search === "?section=about") return "About Us";
    else if (search === "?section=contact") return "Contact Us";
    else if (pathname === "/") return "Home";
    else if (pathname === "/products") return "Products";
    else return "";
  };

  return (
    <BootstrapNavbar
      variant='dark'
      expand='lg'
      className='border-bottom border-bottom-dark position-sticky top-0'
      style={{ zIndex: "1000" }}
    >
      <div className='container'>
        <BootstrapNavbar.Brand as={Link} to='/'>
          <img
            src={logo}
            alt='Mediformers'
            style={{ width: "140px", height: "auto" }}
          />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls='navbarNav' />
        <BootstrapNavbar.Collapse
          id='navbarNav'
          className='justify-content-end'
        >
          <Nav>
            <Nav.Link as={Link} to='/' className='me-3 cursor-pointer'>
              <FaHome /> Home
            </Nav.Link>
            <Nav.Link as={Link} to='/products' className='me-3 cursor-pointer'>
              <FaCapsules /> Products
            </Nav.Link>
            <Nav.Link
              onClick={() => handleRedirect("about")}
              as={ScrollLink}
              to='about'
              smooth={true}
              duration={500}
              className='me-3 cursor-pointer'
            >
              <FaInfoCircle /> About Us
            </Nav.Link>
            <Nav.Link
              onClick={() => handleRedirect("contact")}
              as={ScrollLink}
              to='contact'
              smooth={true}
              duration={500}
              className='cursor-pointer'
            >
              <FaEnvelope /> Contact Us
            </Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
};

export default Navbar;
