import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Modal, Button, Card, Row, Col } from "react-bootstrap";
import LiteratureModal from "./LiteratureModal";
import { useNavigate, useLocation } from "react-router-dom";

const ProductDetailsModal = ({
  product,
  show,
  onHide,
  productUrl,
  showLiteratureModal,
}) => {
  const [showModal, setShowModal] = useState(showLiteratureModal);
  const literatureId = product.id + "_Literature";

  const handleShowModal = () => {
    setShowModal(true);

    navigate(`/products/?modal=${product.id}&literature=${literatureId}`, {
      state: { modal: true },
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate(productUrl, { state: { modal: false } });
  };

  const navigate = useNavigate();
  const location = useLocation();

  // Effect to show the modal based on the 'section' query parameter
  useEffect(() => {
    if (literatureId && location.search.includes(literatureId)) {
      setShowModal(true);
    }
  }, [location, literatureId]);

  // Effect to close the modal if the modal is opened when the user clicks the back button
  useEffect(() => {
    const handlePopstate = (event) => {
      if (!event.state.modal) {
        setShowModal(false);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <Modal
        id={product.id}
        show={show}
        onHide={onHide}
        size='xl'
        className='custom-modal'
      >
        <Modal.Header className='bg-primary-color text-white'>
          <Modal.Title className='fs-2'>
            <span className='text-shadow'>{product.productName} </span>
            <span className='d-inline-block px-5 mb-2 fs-6 fst-italic'>
              Enlist No: {product.enlistNo}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-secondary-color'>
          <Row>
            {/* Right side (Product Image) */}
            <Col xs={12} md={5} className='order-md-2'>
              <div className='d-flex justify-content-center align-items-center h-100 mb-3'>
                {/* Show image at the top for small screens */}
                <div className='d-md-none'>
                  <Card.Img
                    variant='top'
                    src={product.productImage}
                    className='img-fluid'
                    loading='lazy'
                  />
                </div>
                <div className='d-none d-md-block'>
                  {/* Show image on the right for larger screens */}
                  <Card.Img
                    variant='top'
                    src={product.productImage}
                    className='img-fluid'
                    loading='lazy'
                  />
                </div>
              </div>
            </Col>

            {/* Left side (Product Details) */}
            <Col xs={12} md={7} className='order-md-1  fs-6'>
              <Row className='text-primary-color'>
                <h5 className='text-primary-color'>
                  <strong>Product Description:</strong>
                </h5>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {product.productDescription}
                </ReactMarkdown>
              </Row>
              <div className='mb-3 text-primary-color'>
                <p className='text-primary-color mb-1'>
                  <strong>Composition: </strong>
                </p>
                <p className='mb-1'>
                  Each {product.productCompositionContains} contains:{" "}
                </p>
                {product.productComposition.length === 1 ? (
                  <span>{product.productComposition[0]}</span>
                ) : (
                  <ul className='square-bullet-list '>
                    {product.productComposition
                      .slice() // Make a copy of the array to avoid modifying the original array
                      .sort() // Sort the copied array in ascending order (alphabetical order for strings)
                      .map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                )}
              </div>
              <div className='mb-3 text-primary-color'>
                <span className='text-primary-color '>
                  <strong>
                    {product.packageSizes.length === 1
                      ? "Package Size: "
                      : "Package Sizes: "}
                  </strong>
                </span>
                {product.packageSizes.length === 1 ? (
                  <span className='fs-6'>{product.packageSizes}</span>
                ) : (
                  <ul className='square-bullet-list'>
                    {product.packageSizes.map((size, index) => (
                      <li key={index}>{size}</li>
                    ))}
                  </ul>
                )}
              </div>
              <Row className='text-primary-color'>
                <Col xs={6}>
                  <span className='text-primary-color'>
                    <strong>
                      {product.selectedCategories.length === 1
                        ? "Category: "
                        : "Categories:"}
                    </strong>
                  </span>
                  {product.selectedCategories.length === 1 ? (
                    <span className='fs-6'>
                      {product.selectedCategories[0].name}
                    </span>
                  ) : (
                    <ul className='square-bullet-list'>
                      {product.selectedCategories.map((category) => (
                        <li key={category.id}>{category.name}</li>
                      ))}
                    </ul>
                  )}
                </Col>
                <Col xs={6}>
                  <span className='text-primary-color'>
                    <strong>Type: </strong>
                  </span>

                  {product.selectedTypes.map((type) => (
                    <span key={type.id}>{type.name}</span>
                  ))}
                </Col>
              </Row>
              {product.productPDFURL ? (
                <Col xs={12} className='mt-3'>
                  {/* Your other content */}
                  <Button
                    className='border-0 px-4 py-2 shadow'
                    onClick={handleShowModal} // Show the modal when the button is clicked
                  >
                    Show Literature
                  </Button>
                </Col>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className=''>
          <Button
            variant='primary'
            className='shadow border-0 py-2 px-4 '
            onClick={onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Render the LiteratureModal component */}
      <LiteratureModal
        show={showModal}
        onHide={handleCloseModal}
        pdfUrl={product.productPDFURL}
        productName={product.productName}
        literatureId={literatureId}
      />
    </>
  );
};

export default ProductDetailsModal;
