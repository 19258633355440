import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Container,
  Button,
  Row,
  Col,
  Form,
  Modal,
} from "react-bootstrap";
import { FaBuilding, FaEnvelope, FaPhone } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useForm } from "react-hook-form";

import emailjs from "emailjs-com";

import landingImg from "../assets/images/landing-img.jpg";

import exploreProductsBackground from "../assets/images/medicine-bottles.jpg";

import aboutImage1 from "../assets/images/about/medi.jpeg";
import aboutImage2 from "../assets/images/about/slide-2.jpg";
import aboutImage3 from "../assets/images/about/slide-3.jpg";

import missionImage from "../assets/images/lucas-vasques.jpg";

const Home = () => {
  // Array for the images
  const images = [aboutImage1, aboutImage2, aboutImage3];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    const form = e.target;

    // Get form values
    const name = data.name;
    const email = data.email;
    const subject = data.subject;
    const message = data.message;

    // Set up EmailJS user ID
    const EMAILJS_USER_ID = "DeQttonBuIGm3Tmkt";
    emailjs.init(EMAILJS_USER_ID);

    // EmailJS parameters
    const templateParams = {
      from_name: name,
      from_email: email,
      subject: subject,
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send("service_sk5j6qt", "template_kokrf09", templateParams)
      .then(() => {
        // Form submission successful, clear the form
        form.reset();
        setShowSuccessModal(true);
      })
      .catch((error) => {
        setShowErrorModal(true);
      });
  };

  return (
    <>
      {/* Landing Section */}
      <section id='home' className='position-relative'>
        <div
          style={{
            backgroundImage: `url(${landingImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "90vh",
          }}
        >
          <div id='landing-overlay' className='overlay '>
            <Container className='text-justify text-white w-50 mx-5 p-4 p-md-5 my-5 position-absolute bottom-0 end-0  bg-primary-color rounded-3'>
              <h1 className='fw-bold mb-3 text-shadow'>MEDIFORMERS</h1>
              <p className=''>
                Elevate your health and well-being with our premium
                nutraceutical products. We combine the power of science and
                nature to deliver innovative solutions for a healthier
                lifestyle.
              </p>
            </Container>
          </div>
        </div>
      </section>

      {/* Explore Our Products Section */}
      <section
        className='explore-products position-relative'
        style={{ height: "90vh" }}
      >
        {/* Gradient background */}
        <div
          className='d-flex align-items-center justify-content-start h-100'
          style={{
            background: "linear-gradient(to right, #164b60, #16744ebb)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></div>

        {/* Clipped image */}
        <div
          className='d-flex align-items-center justify-content-end h-100 clipped-image'
          style={{
            backgroundImage: `url(${exploreProductsBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 100% 100%)",
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
        ></div>

        {/* Content */}
        <div
          className=' h-100'
          style={{
            position: "relative",
            zIndex: 1,
            paddingLeft: "20px",
          }}
        >
          <Container className='position-absolute bottom-0 text-white text-start p-4 p-md-5 w-50 explore-products-text'>
            <h2 className='text-shadow'>Explore Our Products</h2>
            <p>
              Experience the harmony of science and nature with our premium
              nutraceutical medicines. Each product is thoughtfully developed
              to address specific health needs, providing you with a holistic
              approach to enhance your vitality and promote a balanced
              lifestyle.
            </p>
            <Link to='/products'>
              <Button
                variant='primary'
                className='explore-button border-0 px-3'
              >
                View Products
              </Button>
            </Link>
          </Container>
        </div>
      </section>

      <div>
        {/* About Section */}
        <section id='about' className='my-5 py-5'>
          <Container>
            <Row className='d-flex justify-items-center align-items-center mx-1'>
              {/* Image Slider */}
              <Col sm={12} md={6}>
                <div className='mb-4 mb-md-0'>
                  <Slider {...sliderSettings}>
                    {images.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`${index}-${image}`}
                          className='img-fluid rounded-3 mx-auto d-block'
                          style={{
                            width: "400px",
                            height: "400px",
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>

              {/* Text */}
              <Col
                sm={12}
                md={6}
                className='d-flex jsutify-content-center align-items-center mt-5 mt-md-0 mx-1 mx-md-0 bg-primary-color rounded-3 p-4 p-md-5 text-white'
              >
                <div>
                  <h2 className='fw-bold text-shadow'>About Our Company</h2>
                  <p>
                    Mediformers specializes in nutraceuticals products striving
                    to improve healthcare in Pakistan. Covering a broad range of
                    products, its prime areas of focus are Gynecology,
                    Psychology, Neurology, Urology, Orthopedic and Pediatric.
                    Everyday we strive for a better treatment & improve quality
                    of life for people.
                  </p>
                  <p>
                    Mediformers was founded by Mr. Syed Wasif Hassan in the year
                    2007. Its early operations focused on marketing high quality
                    nutraceutical medicine at an economical price across
                    Pakistan. In 2008 a manufacturing unit was established in
                    Lahore, initiating the in-house production of nutraceuticals
                    medicine. Mediformers expanded its base of operations in
                    2015, partnering with Dr. Umer Masood and Mr. Saud Hassan
                    Syed. The company enlisted in 2017.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Mission Section */}
        <section className='position-relative'>
          {/* Background Image */}
          <div
            className='bg-image'
            style={{
              backgroundImage: `url(${missionImage})`, // Replace with the URL of your background image
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundAttachment: "fixed",
              height: "70vh",
            }}
          ></div>

          {/* Overlay */}
          <div className='overlay d-flex align-items-center'>
            {/* Text */}
            <Container className='text-white z-index-1 bg-primary-color p-4 rounded-3 w-75'>
              <h2 className='text-center text-shadow'>Our Mission</h2>
              <p>
                Providing a path to a healthy life. Through innovation and R &
                D, we aim to achieve the best results via medicine that is
                affordable to everyone in a nation plagued with malnutrition.
                Marketing networks are set up in a manner to ensure availability
                and promote literacy. Our expanding range of products is a means
                to provide a solution to prevalent lapses in nutrition.
              </p>
            </Container>
          </div>
        </section>
      </div>

      {/* Contact Us Section */}
      <section id='contact' className='py-5 mx-3'>
        <Container className='mt-5'>
          <Row>
            {/* Contact Info */}
            <Col
              xs={12}
              md={5}
              className='d-flex flex-column align-items-stretch shadow rounded-3 p-4 me-5 mb-5 mb-md-0'
            >
              <h2 className='text-center mb-4 text-primary-color'>
                Contact Info
              </h2>
              <>
                <Col xs={12} className='text-center'>
                  <Card className='p-4 my-3 bg-primary-color border-0'>
                    <div>
                      <span>
                        <FaBuilding className='text-white fs-3' />
                      </span>
                      <p className='mt-4 mb-0 text-white text-center'>
                        Address: 9-D, P.S.I.E 46 Km Lahore Kasur Road, Punjab,
                        Pakistan
                      </p>
                    </div>
                  </Card>
                </Col>
                <Col xs={12} className='text-center'>
                  <Card className='p-4 my-3 bg-primary-color border-0'>
                    <div>
                      <span>
                        <FaPhone className='text-white fs-3' />
                      </span>
                      <p className='mt-4 mb-0 text-white text-center'>
                        Phone: +92-0321-4559991
                      </p>
                    </div>
                  </Card>
                </Col>
                <Col xs={12} className='text-center'>
                  <Card className='p-4 my-3 bg-primary-color border-0'>
                    <div>
                      <span>
                        <FaEnvelope className='text-white fs-3' />
                      </span>
                      <p className='mt-4 mb-0 text-white text-center'>
                        Email: mediformers@yahoo.com
                      </p>
                    </div>
                  </Card>
                </Col>
              </>
            </Col>

            {/* Contact Form */}
            <Col
              xs={12}
              md={6}
              className='d-flex align-items-stretch justify-content-center shadow bg-primary-color rounded-3'
            >
              <div className='px-2 px-md-4 py-4 text-white w-100'>
                <h2 className='text-center mb-4 text-shadow'>Contact Form</h2>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group controlId='formName' className='mb-3'>
                    <Form.Label className='fw-bold'>Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter your name'
                      {...register("name", { required: "Name is required" })}
                      isInvalid={errors.name}
                      className={"border-0 border-bottom border-white"}
                    />
                    {errors.name && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId='formEmail' className='mb-3'>
                    <Form.Label className='fw-bold'>Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter your email'
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      isInvalid={errors.email}
                      className={"border-0 border-bottom border-white "}
                    />
                    {errors.email && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.email.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId='formSubject' className='mb-3'>
                    <Form.Label className='fw-bold'>Subject</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Subject'
                      {...register("subject", {
                        required: "Subject is required",
                      })}
                      isInvalid={errors.subject}
                      className={"border-0 border-bottom border-white"}
                    />
                    {errors.subject && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.subject.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId='formMessage' className='mb-5'>
                    <Form.Label className='fw-bold'>Message</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      placeholder='Enter your message'
                      {...register("message", {
                        required: "Message is required",
                      })}
                      isInvalid={errors.message}
                      className={"border-0 border-bottom border-white"}
                    />
                    {errors.message && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.message.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <div className='text-center'>
                    <Button
                      variant='primary'
                      type='submit'
                      className='px-4 py-2 border-0 shadow-sm'
                    >
                      Send Message
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col
              xs={12}
              md={11}
              className='mx-0 mx-md-4 my-4 p-4 bg-primary-color rounded-3'
            >
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d995.4137152454327!2d74.43641918180356!3d31.18608912983602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919bb29381e78e9%3A0xcc5cf71b094020b6!2sMediformers%20Nutraceuticals!5e0!3m2!1sen!2s!4v1641355804892!5m2!1sen!2s'
                title='Map'
                width='100%'
                height='100%'
                style={{ border: 0, height: "400px" }}
                allowFullScreen
                className='rounded-3'
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Submitted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you for your message! We will get back to you soon.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          An error occurred while submitting the form. Please try again later.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
