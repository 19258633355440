import React, { useState } from "react";
import { auth } from "../../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import fgPassword from "../../../assets/images/forgot_password.svg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Reset password handler
  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your email.");
    } catch (error) {
      console.log(error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <Container className='vh-100 vw-100 d-flex justify-content-center align-items-center '>
      <Row
        className='my-auto w-100 h-75 rounded-5 shadow-lg'
        style={{
          backgroundImage: `url(${fgPassword})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          opacity: "0.8",
        }}
      >
        <Col className='my-auto'>
          <div
            className='d-flex justify-content-center align-items-center w-50 mx-auto text-white shadow-lg'
            style={{
              background: "linear-gradient(to left, #164b60, #16744ebb)",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <div style={{ width: "70%" }}>
              <h2 className='text-center mb-4 text-shadow'>Forgot Password</h2>
              {message && <p className='text-success'>{message}</p>}
              <Form onSubmit={handleResetPassword} style={{ width: "100%" }}>
                <Form.Group className='mb-3' controlId='email'>
                  <Form.Label className="fw-bold">Email</Form.Label>
                  <Form.Control
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <div className='d-flex justify-content-center align-items-center'>
                  <Button type='submit' variant='primary' className='w-50 border-0 shadow-sm'>
                    Reset Password
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
