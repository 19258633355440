import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className='footer text-white py-2'>
      <Container>
        <p className='m-0 text-center '>
          Copyrights &copy; {new Date().getFullYear()} Mediformers | All rights reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
