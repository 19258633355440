import React from "react";
import { Container } from "react-bootstrap";
import productBG from "../../assets/images/products_bg.jpg";

const ProductHeader = () => {
  return (
    <header
      className='header position-relative'
      style={{
        backgroundImage: `url(${productBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "8rem 0",
      }}
    >
      <div
        className='overlay position-absolute top-0 start-0 w-100 h-100'
        style={{ backgroundColor: "rgb(19, 69, 89, 0.5)", zIndex: 1 }}
      ></div>
      <Container>
        <h1
          className='text-center text-white position-relative'
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", zIndex: 2, fontSize: "3rem" }}
        >
          Our Products
        </h1>
      </Container>
    </header>
  );
};

export default ProductHeader;
