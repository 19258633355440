import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import adminImage from "../../../assets/images/about/medi.jpeg";
import { FaExternalLinkAlt } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  // reset the form
  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);

      // Reset the form
      resetForm();

      // Redirect to the admin dashboard upon successful login
      navigate("/mediadmin/dashboard/add-products");

      // Show success message
      setMessage({ type: "success", text: "Login successful!" });
    } catch (error) {
      // Show error message
      if (error.code === "auth/user-not-found") {
        setMessage({
          type: "error",
          text: "User not found. Please check your username or password.",
        });
      } else {
        setMessage({
          type: "error",
          text: "An error occurred. Please try again later.",
        });
      }
    }

    // Clear the success message after 3 seconds (3000 ms)
    setTimeout(() => {
      setMessage(null);
    }, 3000);
  };
  return (
    <div className='bg-primary-color'>
      <Container>
        <Row>
          <Col md={6} className='my-5 g-0'>
            <div className='d-flex justify-content-center align-items-center h-100 bg-light rounded-start-4 shadow-lg'>
              <div style={{ width: "70%" }}>
                {/* Render message conditionally */}
                {message && (
                  <Alert
                    variant={
                      message.type === "success" ? message.type : "danger"
                    }
                    className='mt-3 text-center'
                  >
                    {message.text}
                  </Alert>
                )}
                <h2 className='text-center mb-4 text-primary-color text-shadow'>
                  Admin Login
                </h2>
                <Form onSubmit={handleLogin} style={{ width: "100%" }}>
                  <Form.Group className='mb-3 ' controlId='email'>
                    <Form.Label className='text-primary-color'>
                      Email
                    </Form.Label>
                    <Form.Control
                      type='email'
                      autoComplete='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='currentPassword'>
                    <Form.Label className='text-primary-color'>
                      Password
                    </Form.Label>
                    <Form.Control
                      type='password'
                      autoComplete='current-password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Button
                      type='submit'
                      variant='primary'
                      className='w-25 border-0'
                    >
                      Login
                    </Button>
                    <Link
                      to='/mediadmin/auth/forgot-password'
                      className='ms-3 text-primary-color'
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </Form>
                <div className='mt-5'>
                  <Link to='/' className='text-primary-color'>
                    <FaExternalLinkAlt /> Back To Website
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className='my-5 g-0'>
            <Image
              src={adminImage}
              alt='Admin'
              fluid
              className='rounded-end-4 shadow-lg'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
