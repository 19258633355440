import React, { useState } from "react";
import { auth } from "../../../firebase";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import {
  FaEye,
  FaEyeSlash,
  FaUserCog,
} from "react-icons/fa";
import { MdVpnKey } from "react-icons/md";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ProgressBar,
  Row,
  Image,
} from "react-bootstrap";
import ConfirmationModal from "../ConfirmationModal";
import passBG from "../../../assets/images/my_password.svg";

function Profile() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Loading indicator
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setShowConfirmationModal(true);
  };

  const handleConfirmPasswordChange = async () => {
    setShowConfirmationModal(false);
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);

      setIsLoading(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
      setError(null);
    } catch (error) {
      setIsLoading(false);
      setError("Failed to change password. Please try again.");
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "currentPassword":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirmPassword":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };
  return (
    <Container>
      <Row className='justify-content-center my-5'>
        <Col md={6}>
          <h1 className='mb-3 text-primary-color'>
            <FaUserCog /> Profile Settings
          </h1>
          <div className='mb-3'>
            <Form.Label className="text-primary-color">Email</Form.Label>
            <Form.Control type='text' value={auth.currentUser.email} disabled />
          </div>
          <Form onSubmit={handleChangePassword}>
            <Form.Group className='mb-3' controlId='currentPassword'>
              <Form.Label className="text-primary-color">Current Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
                <Button
                  variant='outline-secondary'
                  onClick={() => togglePasswordVisibility("currentPassword")}
                  tabIndex={-1}
                  className="bg-primary-color text-white"
                >
                  {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputGroup>
            </Form.Group>

            <Form.Group className='mb-3' controlId='newPassword'>
              <Form.Label className="text-primary-color">New Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <Button
                  variant='outline-secondary'
                  onClick={() => togglePasswordVisibility("newPassword")}
                  tabIndex={-1}
                  className="bg-primary-color text-white"
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputGroup>
            </Form.Group>

            <Form.Group className='mb-3' controlId='confirmPassword'>
              <Form.Label className="text-primary-color">Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <Button
                  variant='outline-secondary'
                  onClick={() => togglePasswordVisibility("confirmPassword")}
                  tabIndex={-1}
                  className="bg-primary-color text-white"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputGroup>
            </Form.Group>

            {error && <p className='text-danger'>{error}</p>}
            {success && (
              <p className='text-success'>Password changed successfully.</p>
            )}

            <div className='text-center'>
              <Button
                type='submit'
                variant='primary'
                className='border-0 fs-5'
                disabled={isLoading}
              >
                {isLoading ? (
                  <ProgressBar
                    animated
                    now={100}
                    label='Changing Password...'
                  />
                ) : (
                  <>
                    Change Password <MdVpnKey />
                  </>
                )}
              </Button>
            </div>
          </Form>

          {/* Modal for Confirmation */}
          <ConfirmationModal
            show={showConfirmationModal}
            onHide={() => setShowConfirmationModal(false)}
            title='Confirm Password Change'
            message='Are you sure you want to change your password?'
            onConfirm={handleConfirmPasswordChange}
            confirmButtonLabel='Change Password'
            cancelButtonLabel='Cancel'
          />
        </Col>
        <Col md={6} className='px-5'>
          <Image src={passBG}></Image>
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
