import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { db, storage } from "../../../firebase";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import ConfirmationModal from "../ConfirmationModal";

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Form, Button, ProgressBar } from "react-bootstrap";
import { FaTrashAlt, FaPlus } from "react-icons/fa";

function ProductForm({ isEditing, productToEdit, editedProductId }) {
  // Form data
  const [formData, setFormData] = useState({
    productName: "",
    productDescription: "",
    productImage: "",
    productPDFURL: "",
    enlistNo: "",
    packageSizes: [],
    MRP: "",
    productComposition: [],
    productCompositionContains: "",
    selectedTypes: [],
    selectedCategories: [],
  });

  const [newPackageSize, setNewPackageSize] = useState("");
  const [compositionItem, setCompositionItem] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  // Categories and Types
  const [categories, setCategories] = useState([
    { id: 1, name: "Cardiology", selected: false },
    { id: 2, name: "Neurology", selected: false },
    { id: 3, name: "Ophthalmology", selected: false },
    { id: 4, name: "Urology", selected: false },
    { id: 5, name: "Orthopedics", selected: false },
    { id: 6, name: "Pediatrics", selected: false },
    { id: 7, name: "Obstetrics & Gynecology", selected: false },
    { id: 8, name: "Dermatology", selected: false },
    { id: 9, name: "Gastroenterology", selected: false },
    { id: 10, name: "Psychiatry", selected: false },
    { id: 11, name: "Genrel Health", selected: false },
    { id: 12, name: "Hepatology", selected: false },
  ]);

  const [types, setTypes] = useState([
    { id: 1, name: "Capsule", selected: false },
    { id: 2, name: "Drops", selected: false },
    { id: 3, name: "Food Supplement", selected: false },
    { id: 4, name: "Sachet", selected: false },
    { id: 5, name: "Syrup", selected: false },
    { id: 6, name: "Tablet", selected: false },
  ]);

  // Loading indicator
  const [isLoading, setIsLoading] = useState(false);

  // Destructure the formData object
  const {
    productName,
    productDescription,
    productImage,
    productPDFURL,
    enlistNo,
    packageSizes,
    MRP,
    productComposition,
    productCompositionContains,
    selectedTypes,
    selectedCategories,
  } = formData;

  // Set the form data from productToEdit when editing a product
  useEffect(() => {
    if (isEditing && productToEdit) {
      // Set the product details from productToEdit
      setFormData({
        productName: productToEdit.productName,
        productDescription: productToEdit.productDescription,
        enlistNo: productToEdit.enlistNo,
        productImage: productToEdit.productImage,
        productPDFURL: productToEdit.productPDFURL,
        packageSizes: productToEdit.packageSizes || [], // If selectedTypes doesn't exist, use an empty array
        productComposition: productToEdit.productComposition || [],
        productCompositionContains: productToEdit.productCompositionContains,
        MRP: productToEdit.MRP,
        selectedTypes: productToEdit.selectedTypes || [],
        selectedCategories: productToEdit.selectedCategories || [],
      });

      setImagePreview(productToEdit.productImage); // Set the image preview
    }
  }, [isEditing, productToEdit]);

  // handleProductNameChange function
  const handleProductNameChange = (e) => {
    setFormData({ ...formData, productName: e.target.value });
  };

  // handleDescriptionChange function
  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    setFormData({ ...formData, productDescription: description });
  };

  const handleTextareaScroll = (event) => {
    // Get the scrollTop value of the textarea
    const { scrollTop } = event.target;

    // Update the preview div's scrollTop to match the textarea's scrollTop
    const previewElement = document.getElementById("formattedDescription");
    if (previewElement) {
      previewElement.scrollTop = scrollTop;
    }
  };

  // handleImageChange function
  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setFormData({ ...formData, productImage: image });

    // Create a temporary URL for the image preview
    setImagePreview(URL.createObjectURL(image));
  };

  // handlePDFFileChange function
  const handlePDFFileChange = (e) => {
    const pdfFile = e.target.files[0];
    setFormData({ ...formData, productPDFURL: pdfFile });
  };

  // handleCategoryChange function
  const handleCategoryChange = (categoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        selected:
          category.id === categoryId ? !category.selected : category.selected,
      }))
    );

    // Create a new array with the selected categories, including id, name, and selected status
    const updatedCategories = categories
      .map((category) => ({
        id: category.id,
        name: category.name,
        selected:
          category.id === categoryId ? !category.selected : category.selected,
      }))
      .filter((category) => category.selected);

    // If it is in 'isEditing' mode, add the previously selected categories to 'updatedSelectedCategories'
    if (isEditing) {
      const previouslySelectedCategories = formData.selectedCategories.filter(
        (category) => category.id !== categoryId
      );
      updatedCategories.push(...previouslySelectedCategories);
    }

    setFormData({
      ...formData,
      selectedCategories: updatedCategories,
    });
  };

  // handleTypeChange function
  const handleTypeChange = (typeId) => {
    setTypes((prevTypes) =>
      prevTypes.map((type) => ({
        ...type,
        selected: type.id === typeId,
      }))
    );

    // Create a new array with the selected type IDs, names, and status
    const selectedTypeIds = types
      .map((type) => ({
        id: type.id,
        name: type.name,
        selected: type.id === typeId,
      }))
      .filter((type) => type.selected === true); // Change the filter condition to type.selected === true

    setFormData({
      ...formData,
      selectedTypes: selectedTypeIds, // Update the selectedTypes with the array of selected types' ID, name, and status
    });
  };

  // handleEnlistNoChange function
  const handleEnlistNoChange = (e) => {
    setFormData({ ...formData, enlistNo: e.target.value });
  };

  // handleMRPChange function
  const handleMRPChange = (e) => {
    setFormData({ ...formData, MRP: e.target.value });
  };

  // PackageSize Functions
  const handlePackageSizeChange = (value, index) => {
    const newPackageSizes = [...packageSizes];
    newPackageSizes[index] = value;
    setFormData({ ...formData, packageSizes: newPackageSizes });
  };

  const handleAddPackageSize = () => {
    if (newPackageSize) {
      setFormData({
        ...formData,
        packageSizes: [...packageSizes, newPackageSize],
      });

      setNewPackageSize("");
    }
  };

  const handleRemovePackageSize = (index) => {
    const newPackageSizes = [...packageSizes];
    newPackageSizes.splice(index, 1);
    setFormData({ ...formData, packageSizes: newPackageSizes });
  };

  // Product Composition Contains Function
  const handleProductCompositionContainsChange = (e) => {
    setFormData({
      ...formData,
      productCompositionContains: e.target.value,
    });
  };

  // Product Composition Functions
  const handleCompositionItemChange = (value, index) => {
    const newProductComposition = [...productComposition];
    newProductComposition[index] = value;
    setFormData({ ...formData, productComposition: newProductComposition });
  };

  const handleAddCompositionItem = () => {
    if (compositionItem) {
      setFormData({
        ...formData,
        productComposition: [...productComposition, compositionItem],
      });

      setCompositionItem("");
    }
  };

  const handleRemoveCompositionItem = (index) => {
    const newProductComposition = [...productComposition];
    newProductComposition.splice(index, 1);
    setFormData({ ...formData, productComposition: newProductComposition });
  };

  // Function to reset the form fields
  const resetFormFields = () => {
    setFormData({
      productName: "",
      productDescription: "",
      productImage: "",
      productPDFURL: "",
      enlistNo: "",
      packageSizes: [],
      MRP: "",
      productComposition: [],
      productCompositionContains: "",
      selectedTypes: [],
      selectedCategories: [],
    });
    setCategories((prevCategories) =>
      prevCategories.map((category) => ({ ...category, selected: false }))
    );
    setTypes((prevTypes) =>
      prevTypes.map((type) => ({ ...type, selected: false }))
    );
    setNewPackageSize("");
    setCompositionItem("");
    setImagePreview("");
    document.getElementById("productImage").value = "";
    document.getElementById("pdfFile").value = "";
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      productName &&
      productDescription &&
      productImage &&
      enlistNo &&
      packageSizes.length > 0 &&
      MRP &&
      productCompositionContains &&
      productComposition.length > 0 &&
      selectedTypes.length > 0 &&
      selectedCategories.length > 0
    )
      setShowConfirmationModal(true);
    else {
      setErrorMessage("Please fill all the fields");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  // Function to handle the final form submission
  const handleConfirmSubmit = async () => {
    setIsLoading(true); // Show loading indicator
    setShowConfirmationModal(false);

    try {
      // Handle the image upload for both adding and editing products
      let updatedProduct = {};

      if (typeof productImage === "object") {
        if (isEditing) {
          // If a new image is selected, delete the previous image (if it exists)
          const prevImageURL = productToEdit.productImage;
          if (prevImageURL) {
            const prevImageRef = ref(storage, prevImageURL);
            await deleteObject(prevImageRef);
          }
        }

        const imageStorageRef = ref(
          storage,
          `product_Images/${Date.now()}_${productImage.name}`
        );
        const imageUploadTask = uploadBytesResumable(
          imageStorageRef,
          productImage
        );

        imageUploadTask.on("state_changed", (error) => {
          console.error("Error uploading image:", error);
          setErrorMessage("Error uploading image");
          setTimeout(() => {
            setErrorMessage(null);
          }, 3000);
        });

        // Wait for the image upload to complete before proceeding to PDF upload
        await imageUploadTask;

        // Get the download URL after the image is uploaded
        const imageDownloadURL = await getDownloadURL(
          imageUploadTask.snapshot.ref
        );

        // Add the image URL to the updated product object
        updatedProduct = {
          ...updatedProduct,
          productImage: imageDownloadURL,
        };
      }

      // Handle the PDF file upload for both adding and editing products
      let pdfUploadCompleted = false; // Flag to check if PDF upload is completed

      if (typeof productPDFURL === "object") {
        if (isEditing) {
          // If a new PDF file is selected, delete the previous PDF file (if it exists)
          const prevPDFURL = productToEdit.productPDFURL;
          if (prevPDFURL) {
            const prevPDFRef = ref(storage, prevPDFURL);
            await deleteObject(prevPDFRef);
          }
        }
        // New PDF file is selected for upload
        const pdfStorageRef = ref(
          storage,
          `pdf_Files/${Date.now()}_${productPDFURL.name}`
        );
        const pdfUploadTask = uploadBytesResumable(
          pdfStorageRef,
          productPDFURL
        );

        pdfUploadTask.on("state_changed", (error) => {
          console.error("Error uploading PDF file:", error);
          setErrorMessage("Error uploading PDF file");
          setTimeout(() => {
            setErrorMessage(null);
          }, 3000);
        });

        // Wait for the PDF file upload to complete before proceeding to product update
        await pdfUploadTask;

        // Get the download URL after the PDF file is uploaded
        const pdfDownloadURL = await getDownloadURL(pdfUploadTask.snapshot.ref);

        // Add the PDF file URL to the updated product object
        updatedProduct = {
          ...updatedProduct,
          productPDFURL: pdfDownloadURL,
        };

        // Set the flag to indicate PDF upload completion
        pdfUploadCompleted = true;
      } else {
        // If no new PDF file is selected, update the product without changing the PDF file URL
        updatedProduct = {
          ...updatedProduct,
          productPDFURL: productPDFURL ? productToEdit.productPDFURL : "",
        };

        // Set the flag to indicate PDF upload completion
        pdfUploadCompleted = true;
      }

      if (!productPDFURL || pdfUploadCompleted) {
        // This condition ensures that if there is no PDF file or the PDF upload is completed,
        // the common product update code will execute.

        // Update the common product properties
        updatedProduct = {
          ...updatedProduct,
          productName,
          productDescription,
          enlistNo,
          packageSizes,
          MRP,
          productComposition,
          productCompositionContains,
          selectedTypes,
          selectedCategories,
        };

        if (isEditing) {
          // If editing, update the product in Firestore using Firebase v9 syntax
          const productRef = doc(db, "Products", editedProductId);
          await updateDoc(productRef, updatedProduct);
          setSuccessMessage("Product updated successfully!");
        } else {
          // If adding a new product, add the product to Firestore
          await addDoc(collection(db, "Products"), updatedProduct);
          setSuccessMessage("Product added successfully!");
        }

        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);

        setIsLoading(false); // Hide loading indicator after the background operations
        // Reset the form fields and success message after successful product update
        resetFormFields();
      }
    } catch (error) {
      console.error("Error uploading image or updating/adding product:", error);
      setErrorMessage("Error uploading image or updating/adding product");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setIsLoading(false); // Hide loading indicator on error
    }
  };

  // Sorting Categories
  const sortedCategories = categories
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  // Sorting Types
  const sortedTypes = types
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className='container my-5'>
      {successMessage && (
        <div className='alert alert-success' role='alert'>
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className='alert alert-danger' role='alert'>
          {errorMessage}
        </div>
      )}

      <Form onSubmit={handleSubmit} className='d-flex'>
        <div className='flex-grow-1 me-5 col-md-3'>
          {/* Product Name */}
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold' htmlFor='productName'>
              Product Name
            </Form.Label>
            <Form.Control
              type='text'
              id='productName'
              value={productName}
              onChange={handleProductNameChange}
              required
            />
          </Form.Group>

          {/* Product Image */}
          <Form.Group className='mb-3'>
            {/* Image Preview */}
            <div>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt='Product Preview'
                  style={{ marginTop: "10px", maxWidth: "200px" }}
                  loading='lazy'
                />
              )}
            </div>
            <Form.Label className='fw-bold' htmlFor='productImage'>
              Product Image
            </Form.Label>
            <Form.Control
              type='file'
              id='productImage'
              accept='.jpg, .jpeg, .png'
              onChange={handleImageChange}
            />
          </Form.Group>

          {/* PDF File */}
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold' htmlFor='pdfFile'>
              Upload Literature
            </Form.Label>
            <Form.Control
              type='file'
              id='pdfFile'
              accept='.pdf' // Accept only PDF files
              onChange={handlePDFFileChange}
            />
          </Form.Group>

          {/* Categories */}
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold'>Categories</Form.Label>
            {sortedCategories.map((category) => (
              <div key={category.id} className='form-check'>
                <Form.Check
                  type='checkbox'
                  id={`category_${category.id}`}
                  checked={formData.selectedCategories.some(
                    (selectedCategory) => selectedCategory.id === category.id
                  )}
                  onChange={() => handleCategoryChange(category.id)}
                  label={category.name}
                />
              </div>
            ))}
          </Form.Group>

          {/* Package Size */}
          <Form.Group className='flex-grow-1 mb-3 me-3'>
            <Form.Label className='fw-bold' htmlFor='packageSize'>
              Package Size
            </Form.Label>
            <div>
              {Array.from({ length: packageSizes.length }, (_, index) => (
                <div key={index} className='d-flex mb-2'>
                  <Form.Control
                    type='text'
                    className='form-control me-2'
                    value={packageSizes[index]}
                    onChange={(e) =>
                      handlePackageSizeChange(e.target.value, index)
                    }
                  />
                  <Button
                    type='button'
                    variant='danger'
                    className='btn-sm col-md-2 border-0 '
                    onClick={() => handleRemovePackageSize(index)}
                  >
                    <FaTrashAlt />
                  </Button>
                </div>
              ))}
            </div>
            <div className='d-flex'>
              <Form.Control
                type='text'
                className='form-control me-2'
                value={newPackageSize}
                onChange={(e) => setNewPackageSize(e.target.value)}
              />
              <Button
                type='button'
                variant='primary'
                className='btn-sm col-md-2 border-0'
                onClick={handleAddPackageSize}
              >
                <FaPlus />
              </Button>
            </div>
          </Form.Group>
        </div>

        <div className='flex-grow-1 col-md-8'>
          {/* Product Description */}
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold' htmlFor='productDescription'>
              Product Description
            </Form.Label>
            <Form.Control
              as='textarea'
              id='productDescription'
              value={productDescription}
              onChange={handleDescriptionChange}
              onScroll={handleTextareaScroll}
              style={{ height: "200px" }}
              required
            />
          </Form.Group>

          {/* Formatted Description Preview */}
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold'>Description Preview:</Form.Label>

            <div
              id='formattedDescription'
              style={{
                height: "200px",
                overflowY: "scroll",
                border: "1px solid #ccc",
                padding: "5px",
              }}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {productDescription}
              </ReactMarkdown>
            </div>
          </Form.Group>

          {/* Types */}
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold'>Types</Form.Label>
            <div className='d-flex' style={{ flexWrap: "nowrap" }}>
              {sortedTypes.map((type) => (
                <div key={type.id} className='form-check me-3'>
                  <Form.Check
                    type='radio'
                    id={`type_${type.id}`}
                    checked={type.id === selectedTypes[0]?.id}
                    onChange={() => handleTypeChange(type.id)}
                    label={type.name}
                  />
                </div>
              ))}
            </div>
          </Form.Group>

          <div className='d-flex'>
            {/* Enlist No. */}
            <Form.Group className='flex-grow-1 mb-3 me-3'>
              <Form.Label className='fw-bold' htmlFor='enlistNo'>
                Enlist No.
              </Form.Label>
              <Form.Control
                type='number'
                id='enlistNo'
                value={enlistNo}
                onChange={handleEnlistNoChange}
                required
              />
            </Form.Group>

            {/* MRP */}
            <Form.Group className='flex-grow-1 mb-3'>
              <Form.Label className='fw-bold' htmlFor='mrp'>
                MRP.
              </Form.Label>
              <Form.Control
                type='number'
                id='MRP'
                value={MRP}
                onChange={handleMRPChange}
                required
              />
            </Form.Group>
          </div>

          <div className='d-flex'>
            {/* Product Composition */}
            <Form.Group className='flex-grow-1 mb-3'>
              <Form.Label className='fw-bold' htmlFor='productComposition'>
                Product Composition
              </Form.Label>
              <Form.Label
                htmlFor='productCompositionContains'
                className='d-flex align-items-center fw-bold'
              >
                Each
                <Form.Control
                  type='text'
                  id='productCompositionContains'
                  value={productCompositionContains}
                  onChange={handleProductCompositionContainsChange}
                  className='w-25 mx-3'
                  required
                />
                contains:
              </Form.Label>

              <div>
                {productComposition
                  .slice()
                  .sort()
                  .map((item, index) => (
                    <div key={index} className='d-flex mb-2'>
                      <Form.Control
                        type='text'
                        className='form-control me-2'
                        value={item}
                        onChange={(e) =>
                          handleCompositionItemChange(e.target.value, index)
                        }
                      />
                      <Button
                        type='button'
                        variant='danger'
                        className='btn-sm col-md-2 border-0'
                        onClick={() => handleRemoveCompositionItem(index)}
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  ))}
              </div>
              <div className='d-flex'>
                <Form.Control
                  type='text'
                  className='form-control me-2'
                  value={compositionItem}
                  onChange={(e) => setCompositionItem(e.target.value)}
                />
                <Button
                  type='button'
                  variant='primary'
                  className='btn-sm col-md-2 border-0'
                  onClick={handleAddCompositionItem}
                >
                  <FaPlus />
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
      </Form>

      {/* From Submit Buttom */}
      <Button
        type='submit'
        variant='primary'
        className='border-0 w-100'
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? (
          <ProgressBar
            animated
            now={100}
            label={isEditing ? "Updating..." : "Adding..."}
            className='border-0'
          />
        ) : isEditing ? (
          "Update"
        ) : (
          "Add Product"
        )}
      </Button>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title={isEditing ? "Edit Product" : "Add Product"}
        message={
          isEditing
            ? "Are you sure you want to edit this product?"
            : "Are you sure you want to add a new product?"
        }
        cancelButtonLabel='Cancel'
        confirmButtonLabel='Confirm'
        onConfirm={handleConfirmSubmit}
      />
    </div>
  );
}

export default ProductForm;
