import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  onHide,
  title,
  message,
  cancelButtonLabel,
  confirmButtonLabel,
  onConfirm,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} className="border-0">
          {cancelButtonLabel}
        </Button>
        <Button variant={title === "Confirm Deletion" ? "danger" : "primary"} onClick={onConfirm} className="border-0">
          {confirmButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
