import React from "react";
import { Card } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

const ProductCard = ({ product, onClick }) => {
	return (
		<Card
			className='h-100 product-card shadow-sm cursor-pointer scale-hover position-relative border-0 rounded'
			onClick={onClick}>
			{/* The overlay */}
			<div className='position-absolute top-0 start-0 w-100 h-100 bg-primary-opacity text-primary-color rounded fs-2 d-flex flex-column justify-content-center align-items-center'>
				<p className='fw-bold fs-4'>Click to Preview</p>
				<FaEye />
			</div>

			<div
				className='product-image-container rounded'
				style={{
					backgroundImage: `url(${product.productImage})`,
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					width: "100%",
					height: "100%",
				}}></div>
		</Card>
	);
};

export default ProductCard;
