import React from "react";
import { FormControl, Form, Button, FormCheck } from "react-bootstrap";
import { MdFilterAlt } from "react-icons/md";

const ProductFilter = ({
	searchQuery,
	setSearchQuery,
	selectedType,
	setSelectedType,
	selectedCategory,
	setSelectedCategory,
	types,
	categories,
	mobile,
	onHide,
}) => {
	// Function to reset all filters to their initial state
	const handleResetFilters = () => {
		setSearchQuery("");
		setSelectedType("");
		setSelectedCategory([]);
	};

	// Function to handle checkbox toggle
	const handleCategoryToggle = (categoryName) => {
		if (selectedCategory.includes(categoryName)) {
			// Category is already selected, remove it
			setSelectedCategory(
				selectedCategory.filter((category) => category !== categoryName)
			);
		} else {
			// Category is not selected, add it
			setSelectedCategory([...selectedCategory, categoryName]);
		}
	};

	return (
		// <div className="bg-primary-color p-4 rounded text-white" >
		//   <h3 className='text-center'>
		//     <MdFilterAlt /> Filter Products
		//   </h3>
		//   <FormControl
		//     type='text'
		//     placeholder='Search...'
		//     value={searchQuery}
		//     onChange={(e) => setSearchQuery(e.target.value)}
		//     className='mb-3'
		//   />

		//   <h5>Types:</h5>
		//   <Form.Select
		//     value={selectedType}
		//     onChange={(e) => setSelectedType(e.target.value)}
		//   >
		//     <option value=''>All Types</option>
		//     {types.map((type) => (
		//       <option key={type.id} value={type.name}>
		//         {type.name}
		//       </option>
		//     ))}
		//   </Form.Select>

		//   <h5 className='mt-3'>Categories:</h5>
		//   {categories.map((category) => (
		//     <FormCheck
		//       key={category.id}
		//       type='checkbox'
		//       label={category.name}
		//       checked={selectedCategory.includes(category.name)}
		//       onChange={() => handleCategoryToggle(category.name)}
		//       id={`category-${category.id}`} // Unique id for each checkbox
		//     />
		//   ))}

		//   {/* Add the Remove Filter button */}
		//   <Button variant="transparent" className='mt-3 w-100 border-white text-white' onClick={handleResetFilters}>
		//     Remove Filters
		//   </Button>
		// </div>

		<div className='bg-primary-color p-4 rounded text-white'>
			<h3 className='text-center'>
				<MdFilterAlt size={mobile ? "1.5rem" : "2rem"} /> Filter Products
			</h3>
			<FormControl
				type='text'
				placeholder='Search...'
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				className='mb-3'
				size={mobile ? "sm" : "md"}
			/>

			<h5>Types:</h5>
			<Form.Select
				value={selectedType}
				onChange={(e) => setSelectedType(e.target.value)}
				size={mobile ? "sm" : "md"}>
				<option value=''>All Types</option>
				{types.map((type) => (
					<option key={type.id} value={type.name}>
						{type.name}
					</option>
				))}
			</Form.Select>

			<h5 className='mt-3'>Categories:</h5>
			{categories.map((category) => (
				<FormCheck
					key={category.id}
					type='checkbox'
					label={category.name}
					checked={selectedCategory.includes(category.name)}
					onChange={() => handleCategoryToggle(category.name)}
					id={`category-${category.id}`} // Unique id for each checkbox
					size={mobile ? "sm" : "md"}
					style={{ fontSize: mobile ? "0.8rem" : "1rem" }}
				/>
			))}

			{/* Add the Remove Filter button */}
			<div className='d-flex'>
				{mobile && (
					<Button
						variant='transparent'
						className='mt-3 w-100 border-white text-white me-2'
						onClick={onHide}
						size='sm'>
						Done
					</Button>
				)}
				<Button
					variant='transparent'
					className='mt-3 w-100 border-white text-white'
					onClick={handleResetFilters}
					size={mobile ? "sm" : "md"}>
					Remove Filters
				</Button>
			</div>
		</div>
	);
};

export default ProductFilter;
