import React from "react";
import { Modal, Button } from "react-bootstrap";

const LiteratureModal = ({ show, onHide, pdfUrl, productName, literatureId }) => {
  return (
    <Modal id={literatureId} show={show} onHide={onHide} size='xl'>
      <Modal.Header className='bg-primary-color text-white'>
        <Modal.Title className='text-shadow'>
          {productName} Literature
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className='bg-primary-color rounded-3'
        >
          <iframe
            src={`https://docs.google.com/gview?url=${encodeURIComponent(
              pdfUrl
            )}&embedded=true`}
            title='PDF Viewer'
            style={{
              width: "90%",
              height: "90%", // Adjust the height to your preference
            }}
            className="border-0 rounded-3 shadow-lg"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={onHide} className='border-0'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LiteratureModal;
