import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { deleteObject, ref } from "firebase/storage";

import {
  Card,
  Button,
  Modal,
  Container,
  Row,
  Col,
  FormControl,
} from "react-bootstrap";
import ProductForm from "./ProductForm";
import ConfirmationModal from "../ConfirmationModal";

import { FaEdit } from "react-icons/fa";

function ManageProducts() {
  const [products, setProducts] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [productToEdit, setProductToEdit] = useState({});
  const [editedProductId, setEditedProductId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [productName, setProductName] = useState("");

  // State to handle search query
  const [searchQuery, setSearchQuery] = useState("");

  // State to handle errors and success messages
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Products"));
        const fetchedProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Accessing the automatically generated ID
          ...doc.data(),
        }));

        setProducts(fetchedProducts);
      } catch (error) {
        console.log("Error fetching products:", error);
      }
    };

    // Fetch the initial products data
    fetchProducts();

    // Set up a real-time listener for product updates
    const productsCollectionRef = collection(db, "Products");
    const unsubscribe = onSnapshot(productsCollectionRef, (snapshot) => {
      const updatedProducts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProducts(updatedProducts);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Function to truncate the description to 35 characters
  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
      return description;
    } else {
      return description.substring(0, maxLength) + "...";
    }
  };

  // Function to set the product to be edited and show the modal
  const handleEdit = (productId) => {
    const product = products.find((product) => product.id === productId);

    setProductToEdit(product);
    setEditedProductId(productId); // New line to set the editedProductId
    setShowEditModal(true);
  };

  // Function to delete the product from Firestore and Storage
  const handleConfirmDelete = async () => {
    try {
      const { id, productImage, productPDFURL } = productToDelete;

      // Delete the image from Firebase Storage if productImage exists
      if (productImage) {
        const imageRef = ref(storage, productImage);
        await deleteObject(imageRef);
      }

      // Delete the PDF from Firebase Storage if productPDFURL exists
      if (productPDFURL) {
        const pdfRef = ref(storage, productPDFURL);
        await deleteObject(pdfRef);
      }

      // Delete the product from Firestore using Firebase v9 syntax
      const productRef = doc(db, "Products", id);
      await deleteDoc(productRef);

      // Update the local state to remove the product
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== id)
      );

      // Show success message
      setShowDeleteConfirmation(false);
      setSuccessMessage("Product deleted successfully!");

      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      // Handle the error and show error message
      setErrorMessage("Error deleting product. Please try again later.");

      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  };

  // Function to show the confirmation modal and set the product to be deleted
  const handleShowConfirmationModal = (product) => {
    setProductToDelete(product);
    setShowDeleteConfirmation(true);
  };

  // Update the local state when productToEdit changes
  useEffect(() => {
    if (productToDelete) {
      setProductName(productToDelete.productName);
    }
  }, [productToDelete]);

  // Function to close the modal and reset the productToEdit state
  const handleModalClose = () => {
    setShowEditModal(false);
    setProductToEdit({});
  };

  // Sort the products by name
  const sortedProducts = products
    .slice()
    .sort((a, b) => a.productName.localeCompare(b.productName));

  // Filtered products based on search and filter criteria
  const filteredProducts = sortedProducts.filter((product) => {
    return product.productName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  });

  // Maximum number of list items to show in the Composition and Categories lists
  const MAX_LIST_ITEMS = 3;

  return (
    <Container className='my-5 '>
      <h1 className='text-center text-primary-color'>
        <FaEdit /> Manage Products
      </h1>
      <hr className='mb-3' />
      <div
        className='w-50 mx-auto position-sticky '
        style={{ zIndex: 1000, top: "5rem", left: "0" }}
      >
        <FormControl
          type='text'
          placeholder='Search...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className='mb-5 bg-primary-color text-white'
        />
      </div>

      {/* Show error message if there's an error */}
      {errorMessage && (
        <div className='alert alert-danger' role='alert'>
          {errorMessage}
        </div>
      )}

      {/* Show success message if product is deleted successfully */}
      {successMessage && (
        <div className='alert alert-success' role='alert'>
          {successMessage}
        </div>
      )}

      <Row>
        {/* Map through the products array and render a Card for each product */}
        {filteredProducts.map((product) => (
          <Col md={4} key={product.id} className='mb-4'>
            <Card
              className='h-100'
              style={{
                border: "none",
                borderRadius: "10px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className='bg-light'
                style={{
                  height: "250px",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <Card.Img
                  variant='top'
                  src={product.productImage} // Update the image URL property
                  loading='lazy'
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
              <Card.Body className='d-flex flex-column'>
                <Card.Title className='mb-0 fw-bold text-primary-color'>
                  {product.productName}
                </Card.Title>
                <Card.Text className='text-primary-color mt-2 mb-3'>
                  {/* Enlist No. and Type */}
                  <div className='d-flex justify-content-between'>
                    <Col xs={7}>
                      <span className='fw-bold'>Enlist No.: </span>
                      {product.enlistNo}
                    </Col>
                    <Col xs={5}>
                      <span className='fw-bold'>Type: </span>{" "}
                      {product.selectedTypes.find((type) => type.selected)
                        ?.name || "N/A"}
                    </Col>
                  </div>

                  {/* Description and MRP */}
                  <div className='d-flex justify-content-between mt-2 text-primary-color'>
                    <Col xs={8} className=''>
                      <span className='fw-bold'>Description:</span>{" "}
                      <p>
                        {truncateDescription(product.productDescription, 50)}
                      </p>
                    </Col>
                    <Col xs={3} className=''>
                      <span className='fw-bold'>MRP:</span> {product.MRP}
                    </Col>
                  </div>

                  {/* PDF */}
                  <div className='d-flex justify-content-between mt-2'>
                    <Col xs={12} className='text-primary-color'>
                      <span className='fw-bold'>Literature: </span>{" "}
                      {product.productPDFURL ? (
                        <a
                          href={product.productPDFURL}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View PDF
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </Col>
                  </div>

                  {/* Composition and Categories */}
                  <div className='d-flex justify-content-between mt-2'>
                    <Col xs={6} className='text-primary-color'>
                      <span className='fw-bold'>Composition:</span>
                      <ul className='square-bullet-list '>
                        {product.productComposition &&
                          product.productComposition.map(
                            (compositionItem, index) => {
                              if (index < MAX_LIST_ITEMS) {
                                return <li key={index}>{compositionItem}</li>;
                              } else if (index === MAX_LIST_ITEMS) {
                                return <li key={index}>and more...</li>;
                              } else {
                                return null;
                              }
                            }
                          )}
                      </ul>
                    </Col>
                    <Col xs={6} className='text-primary-color'>
                      <span className='fw-bold'>Categories:</span>
                      <ul className='square-bullet-list '>
                        {product.selectedCategories ? (
                          product.selectedCategories.map((category, index) => {
                            if (index < MAX_LIST_ITEMS) {
                              return <li key={category.id}>{category.name}</li>;
                            } else if (index === MAX_LIST_ITEMS) {
                              return <li key={index}>and more...</li>;
                            } else {
                              return null;
                            }
                          })
                        ) : (
                          <p>N/A</p>
                        )}
                      </ul>
                    </Col>
                  </div>
                </Card.Text>

                {/* Edit and Delete Buttons */}
                <div className='mt-auto'>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='primary'
                      onClick={() => handleEdit(product.id)}
                      style={{ width: "100%" }}
                      className='border-0'
                    >
                      Edit
                    </Button>
                  </div>
                  <div className='d-flex justify-content-center mt-2'>
                    <Button
                      variant='danger'
                      onClick={() => handleShowConfirmationModal(product)}
                      style={{ width: "100%" }}
                      className='border-0'
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Edit Modal */}
      <Modal
        show={showEditModal}
        onHide={handleModalClose}
        className='custom-modal'
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForm
            isEditing={true}
            productToEdit={productToEdit}
            editedProductId={editedProductId}
          />
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        title='Confirm Deletion'
        message={
          <React.Fragment>
            Are you sure you want to delete the product{" "}
            <span className='text-danger fw-bold'>{productName}</span>?
          </React.Fragment>
        }
        cancelButtonLabel='Cancel'
        confirmButtonLabel='Delete'
        onConfirm={() => handleConfirmDelete()}
      />
    </Container>
  );
}

export default ManageProducts;
